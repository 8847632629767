import { ModalLineConnect } from '#components'

export default defineNuxtRouteMiddleware(async (to, from) => {
    const subdomain = useSubdomain()
    const tenantPinia = useTenantPinia()
    const authStore = useAuthStore()
    const modal = useModal()
    const appConfig = useAppConfig()
    if (tenantPinia.tenant) {
        if (tenantPinia.tenant?.site_setting?.theme_color) {
            appConfig.ui.primary = tenantPinia.tenant.site_setting.theme_color
        } else {
            appConfig.ui.primary = 'indigo'
        }
        if (tenantPinia.tenant?.customer_registered === false && authStore.isLoggedIn) {
            const { status, error } = await useBackend('/v1/public/customer/join/', {
                method: 'POST',
                body: {
                    store: tenantPinia.tenant.id,
                },
                watch: false,
            })
            await tenantPinia.fetchTenant(subdomain, true)
            if (status.value === 'error') {
                throw createError({
                    statusCode: error.value?.statusCode,
                    message: error.value?.message,
                })
            }
        }
        if (tenantPinia.tenant?.customer_registered === true && authStore.isLoggedIn && tenantPinia.tenant?.customer?.line_users?.length === 0 && from.query.linkToken === undefined) {
            modal.open(ModalLineConnect, {
                line_bots: tenantPinia.tenant.line_bots,
            })
        }
        if (tenantPinia.tenant?.customer_registered === true && authStore.isLoggedIn && from.query.linkToken) {
            const { status, data } = await useBackend('/v1/public/customer/{id}/link-apply/', {
                method: 'POST',
                body: {
                    link_token: from.query.linkToken,
                },
                watch: false,
                path: {
                    id: tenantPinia.tenant.customer.id,
                },
            })
            if (status.value === 'success') {
                window.location.href = `https://access.line.me/dialog/bot/accountLink?linkToken=${data.value?.link_token}&nonce=${data.value?.nonce}`
                await tenantPinia.fetchTenant(subdomain, true)
            }
        }
        if (!authStore.isLoggedIn && from.query.linkToken) {
            const runtimeConfig = useRuntimeConfig()
            window.location.href = `${runtimeConfig.public.AUTH_ORIGIN}${runtimeConfig.public.LOGIN_PATH}?redirect=${urlSafeBtoa(to.fullPath)}&from=liff&tenant=${tenantPinia.tenant?.slug}`
        }
    } else {
        await tenantPinia.fetchTenant(subdomain)
    }
})
